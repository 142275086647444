.toast-container {
  position: initial;

  .toast {
    margin: 10px;
    position: fixed;
    z-index: 1056;
    top: 0;
    right: 0;
    min-height: 40px;
    width: 400px;
    border-radius: 6px;
    color: $bm-white;
    border: none;
  }

  .toast-header {
    border: none;
    background-color: transparent;
    color: $bm-white;

    .close {
      text-shadow: none;
    }
  }

  .toast-body {
    padding: 0.75rem 1rem;
  }

  &.success .toast {
    background-color: lighten($bm-green, 10%);
    color: $bm-white;
  }

  &.success .toast-header .close {
    color: darken($bm-green, 15%);
  }

  &.info .toast {
    background-color: lighten($bm-yellow, 10%);
    color: darken($bm-yellow, 35%);
  }

  &.info .toast-header {
    background-color: lighten($bm-yellow, 20%);
    color: darken($bm-yellow, 35%);
  }

  &.info .toast-header .close {
    color: darken($bm-yellow, 35%);
  }

  &.error .toast {
    background-color: lighten($bm-red, 10%);
    color: $bm-white;
  }

  &.error .toast-header .close {
    color: darken($bm-red, 15%);
  }

  .body {
    line-height: 1.4;
    margin: 0;
  }

  &--bottom {
    .toast {
      top: auto;
      bottom: 12px;
      right: 10px;
      background-color: $bm-white;
      color: black;

      .toast-body {
        padding: 0.75rem 1rem;
      }

      .toast-header {
        color: black;
        padding: 0;

        strong {
          font-weight: 500;
          text-transform: uppercase;
        }
      }

      .toast-body-content {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        gap: 6px;
      }
    }

    &.success .toast,
    &.error .toast {
      background-color: $bm-white;
      color: black;
    }

    .success-icon {
      font-size: 6em;
      color: $bm-green;
    }

    .error-icon {
      font-size: 6em;
      color: $bm-red;
    }
  }
}
