ol.parent > li,
ol.subParent > li {
  list-style-type: none;
}

ol.parent,
ol.subParent {
  //border: 1px solid green;
  counter-reset: item;
  padding-left: 0;
}

ol.subParent > li > *:not(b) {
  padding-left: 5.5vw; /* add indentation for all li content */
  //border: 2px solid green;
}
li:before {
  width: 4vw;
  //border: 2px solid purple;
}

//for small devices
@media only screen and (max-width: 576px) {
  li:before {
    width: 10vw;
  }
}

ol.parent > li:before {
  font-weight: bold;
}
ol.parent > li:before,
ol.subParent > li:before {
  display: inline-block;
  content: counters(item, '.') '.';
  counter-increment: item;
}

/*roman list*/
@counter-style romanWithParentheses {
  system: cyclic;
  symbols: '(i)' '(ii)' '(iii)' '(iv)' '(v)' '(vi)' '(vii)' '(viii)';
  suffix: ' ';
}

ol[type='i'] {
  list-style: romanWithParentheses;
}

/*alphabet list*/
@counter-style alphabetWithParentheses {
  system: cyclic;
  symbols: '(a)' '(b)' '(c)' '(d)' '(e)' '(f)' '(g)' '(h)' '(i)' '(j)' '(k)'
    '(l)';
  suffix: ' ';
}

ol[type='a'] {
  list-style: alphabetWithParentheses;
}

ol[type='a'] > li,
ol[type='i'] > li,
ol.subParent > li {
  margin-top: 1em;
  margin-bottom: 1em;
}

.contact > div,
.contact > div > p {
  padding: 0.5vh;
}
