.filtersList {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;
  row-gap: 15px;

  @media all and (max-width: 768px) {
    column-gap: 0;
    row-gap: 0;
  }

  @media all and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
  }

  @media all and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .inputsRange {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;

    @media all and (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
    }
  }

  .Input {
    div[class$='-control'] {
      min-height: 40px;
      height: auto;
    }

    @media all and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
}
