.position-input {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  .position-line {
    display: flex;
    .position-tile {
      cursor: pointer;
      height: 10px;
      width: 10px;
      margin: 1px;
      border: 1px solid $bm-gray;
      background-color: $bm-gray;
      &.position-active {
        border: 1px solid $bm-orange;
        background-color: $bm-orange;
      }
    }
  }
}
.position-value {
  display: inline;
  vertical-align: middle;
  margin-left: 4px;
}
