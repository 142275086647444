@import '../../../styles/_variables.scss';

.editorClassName {
  min-height: 200px;
  border: 1px solid $bm-gray-light;
  cursor: text;
  padding: 0 5px;

  li {
    div {
      margin: 1px 0;
    }
  }
}
