.provider-link {
  margin-left: 2px;
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 14px;
}

.campaignEditForm {
  .Input {
    margin-bottom: 20px;
    padding-right: 10px;
    min-width: 300px;
  }
}
