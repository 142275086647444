@import '../variables';
.carouselInput {
  .carouselInputInfo {
    font-size: 14px;
    margin-bottom: 1rem;
    color: $gray-800;
  }

  .carouselInputThumbs {
    position: relative;
    width: 100%;
    display: inline-flex;
    overflow-x: auto;
    padding: 10px 0;
    margin: 10px 0;

    .carouselInputThumb {
      border: 2px solid $bm-gray-light;
      margin-right: 5px;
      width: 75px;
      height: 75px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: relative;

      &--selected {
        border: 2px solid $bm-orange;
      }

      .removeThumb {
        position: absolute;
        z-index: 1;
        top: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        opacity: 0.5;
        color: $bm-gray;
        background: $bm-white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: opacity ease-in-out 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
