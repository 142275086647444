.landing-page-edit-form {
  .formInput {
    max-width: 266px;
  }
}

.campaignEditPage {
  .btnDefault {
    margin-right: 15px;
  }
}

.video-provider-select {
  select {
    min-width: 152px;

    @media (max-width: 992px) {
      min-width: 132px;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
}

.col-auto {
  .Input {
    min-width: 300px;
  }

  .Input .css-26l3qy-menu {
    z-index: 2;
  }
}

.cpep-short-paragraph {
  max-width: 300px;
}
