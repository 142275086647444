// DEFAULT BUTTON

.btnDefault {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 20px;
  border: none;
  border-radius: 12px;
  color: $bm-white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1;
  font-size: 14px;
  background-color: $primary;
  transition: opacity 0.2s ease-in-out;
  box-shadow: 2px 2px 2px 0 transparentize($bm-black, 0.87);
  white-space: nowrap;
  text-decoration: none;

  @include media-breakpoint-down(md) {
    height: 38px;
    font-size: 14px;
  }

  &:hover {
    background-color: $primary;
    opacity: 0.7;
    color: $bm-white;
    text-decoration: none;
  }

  &:focus,
  &:active {
    background-color: darken($bm-orange, 10%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.35;

    &:hover {
      color: $bm-white;
    }
  }

  &::after {
    display: none;
  }

  &.btn-primary:not(:disabled):not(.disabled):active,
  &.btn-primary:not(:disabled):not(.disabled).active,
  .show > &.btn-primary {
    background-color: $primary;
    border-color: $primary;
  }
}

// DEFAULT BUTTON VARIATIONS

.btnDefault {
  &--medium {
    height: 38px;
    border-radius: 8px;
    padding: 6px 16px;
    font-size: 14px;
  }

  &--small {
    height: 24px;
    padding: 0 12px;
    font-size: 11px;
    border-radius: 4px;
  }

  &--red {
    background-color: $bm-red;

    &:hover {
      background-color: $bm-red;
    }
  }

  &--light {
    color: $bm-gray;
    background-color: $bm-gray-light;

    &:hover {
      color: $bm-gray;
      background-color: $bm-gray-light;
    }

    &:active,
    &:focus {
      color: $bm-black;
      background-color: $bm-gray !important;
      outline: none;
    }
  }

  &--green {
    background-color: $bm-green;

    &:hover {
      background-color: $bm-green;
    }
  }

  &--white {
    background-color: $bm-white !important;
    color: $bm-black;
    border: #ddd 1px solid;
    box-shadow: none;

    &:hover {
      color: $bm-black;
      background-color: $bm-white !important;
    }

    &:active,
    &:focus {
      color: $bm-black;
      background-color: $bm-white !important;
      outline: none;
    }
  }

  &--gray {
    background-color: $bm-gray;

    &:hover {
      background-color: $bm-gray;
    }
  }

  &--with-icon {
    &__icon {
      font-size: 22px;
      margin-left: 10px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }

    &-left {
      .btnDefault--with-icon__icon {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}

.btnRemove {
  color: $bm-red;
  background-color: transparent;
  border: none;
  height: 20px;
  font-size: 16px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: darken($bm-red, 12%);
  }
}

.btnContainer {
  display: flex;
  column-gap: 10px;
}
