@use '../../styles/_variables.scss' as v;

.dashboardContainer {
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .small {
      line-height: 1.3;
    }
    .extra-small {
      font-size: 12px;
      line-height: 1.2;
    }
    .flex-grow {
      flex-grow: 1;
    }
    .flex-gap {
      column-gap: 4px;
      row-gap: 4px;
    }
  }
}

.campaignCard,
.providerCard {
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  background: white;
  cursor: pointer;
}

.campaignCard {
  min-height: 200px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: all 0.1s ease-in-out !important;
  height: 100%;
  flex-grow: 1;

  &:hover {
    .chevronRightButtonSecondary {
      :global {
        div {
          opacity: 0.7;
        }
      }
    }
  }
}

.providerCard {
  padding: 8px 16px;
  font-size: 14px;
  width: 100%;
  height: 42px;
  column-gap: 12px;

  :global {
    p {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    svg {
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.providerGroup {
  row-gap: 12px;
}

.chevronRightButton,
.chevronRightButtonSecondary {
  text-decoration: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    :global {
      div {
        opacity: 0.7;
      }
    }
  }

  :global {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: v.$bm-orange;
      height: 24px;
      width: 24px;
      transition: opacity 0.2s ease-in-out;
    }

    svg {
      margin-left: 1px;
    }
  }
}

.chevronRightButtonSecondary {
  :global {
    div {
      background-color: #d1d1d1;
      height: 18px;
      width: 18px;
    }
  }
}

.campaignStats {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  column-gap: 16px;
  :global {
    p {
      margin-bottom: 0;
    }
    .separator {
      background-color: #d1d1d1;
      height: 16px;
      width: 1px;
    }
  }
}

.highlightText {
  color: v.$inactive;
  font-weight: 600;
}

.cardHeader {
  max-width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.connectionPill {
  font-size: 10px;
  padding: 2px 12px;
  border: 1px #ff6f00 solid;
  border-radius: 20px;
}
