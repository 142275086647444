@import '../../../styles/_variables.scss';

.accordionItem {
  padding: 0 25px;
  max-width: 1300px;
  margin: 0 auto;

  --bs-accordion-active-bg: #fafafa;
  --bs-accordion-border-radius: 15px;
  --bs-accordion-inner-border-radius: 15px;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(253, 125, 13, 0.346);

  // Bootstrap modifiers
  :global {
    .accordion-item {
      border: 0;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px 2px;
    }
    .accordion-body {
      padding-bottom: 0;
    }
    .accordion-button {
      color: black;
      padding: 20px 30px;

      &:hover {
        z-index: initial; // Default is 2, and breaks tooltip overlay
      }

      &:not(.collapsed) {
        color: black;
        box-shadow: none;
      }

      &:first-child {
        &::after {
          content: url('../../../assets/icons/accordion-arrow-down.svg');
          background-image: none;
        }
      }
    }
  }

  .statusIndicatorColumn {
    width: 75px;
    max-width: 75px;
    margin-top: 17px;
  }

  .statusIndicator {
    position: relative;
    z-index: 1;
    flex-grow: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: $fallback;

    &Success {
      background: $active;
    }

    &Error {
      background: $inactive;
    }

    &Pending {
      color: white;
    }
  }

  .accordionContent {
    --bs-accordion-btn-icon-width: 26px; // Fix arrow icon rotation position

    flex-grow: 1;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -32px;
      width: 1px;
      height: calc(100% - 50px);
      top: 70px;
      background: #e2dddd;
    }
  }

  &:last-child .accordionContent:before {
    display: none;
  }

  .accordionBody {
    padding: 35px 30px 25px;
  }
}

.toolTip {
  position: absolute;
  font-size: 1rem;
  color: black;
  background: white;

  ul {
    margin: 0;
    padding-left: 1.25rem;
  }
}
