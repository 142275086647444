.link-bm-orange {
  color: $bm-orange;
  text-decoration: underline;

  &:hover {
    color: $bm-orange;
    text-decoration: none;
  }
  &:active,
  &:focus {
    color: $bm-orange;
    text-decoration: none;
  }
}
