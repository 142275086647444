@import '../../../../styles/variables';
@import '~bootstrap/scss/bootstrap';

.creator-settings-section {
  position: fixed;
  height: calc(100vh - 350px);
  background-color: lighten($bm-gray-light, 6%);
  border-radius: 6px;
  box-shadow: 0 0 5px transparentize($bm-black, 0.87);
  max-width: 100%;
  width: 50vw !important;
  min-height: 80vh;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  padding-top: 70px;

  @include media-breakpoint-down(lg) {
    min-width: auto;
    width: 65vw !important;
    min-height: 90vh;
  }

  @include media-breakpoint-down(sm) {
    width: calc(100vw - 30px) !important;
  }

  .btnDefault {
    height: 38px;
    font-size: 14px;
  }

  &__header {
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    padding: 10px 15px;
    background-color: $bm-orange;
    color: $bm-white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .creator-settings-section__close {
      cursor: pointer;
      z-index: 1;
      font-size: 20px;
      transition: opacity 0.25s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__inner {
    padding: 0 20px 20px 20px;
    height: 100%;
    overflow-y: auto;
  }

  &__description {
    font-size: 0.9em;
    color: $bm-gray;
    border-bottom: 1px solid $bm-gray-light;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;

    .img-container {
      flex: 0 0 35%;
      margin-right: 15px;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__language {
    font-size: 10px;
  }
}

.creator-settings-section__backdrop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
  background-color: transparentize($bm-black, 0.3);
  display: block;
}
