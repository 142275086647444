@import '../../../styles/_variables.scss';
@import '../../../styles/_bootstrapOverrides.scss';

.displayNumbersWrapper {
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 650px) {
    justify-content: center;
  }
}
.displayNumbers {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15px 20px;
  border-radius: 6px;
  color: $bm-black;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
  background-color: $gray-100;
  box-shadow: 2px 2px 2px 0 transparentize($bm-black, 0.87);
  white-space: nowrap;
  margin: 10px !important;

  @media (max-width: 992px) {
    font-size: 16px;
    padding: 10px 15px;
  }

  @media (max-width: 650px) {
    font-size: 14px;
    flex-basis: 40%;
  }

  @media (max-width: 400px) {
    font-size: 13px;
    flex-basis: 40%;
  }
}
