.defaultPagination {
  justify-content: center;

  .PaginationItem {
    .page-link {
      color: $bm-black;
    }

    &.active {
      .page-link {
        background-color: $primary;
        border-color: $primary;
        color: $bm-white;
      }
    }
  }
}
