.postQuestion {
  background: #fafafa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 16px;
}

.postQuestionDisabled {
  pointer-events: none;
  opacity: 0.25;
}

.postQuestionAnswers {
  min-height: 134px !important;
}

.formInputInvalid {
  border: 1px solid red !important;
}
