@import '../../../../../../styles/variables';
@import '../../../../../../styles/bootstrapOverrides';

.chartTypeSelect-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    display: block;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
    margin-left: 4px;
    color: #6a6a6a;
    flex-basis: 100%;
  }
}

.chartTypeSelect {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  &--button {
    background-color: $bm-orange;
    cursor: pointer;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.25s ease-in-out,
      background-color 0.15s ease-in-out 0.1s;
    margin-right: 10px;
    border: none;

    &:focus {
      outline: none;
    }

    svg {
      color: $bm-white;
      transition: color 0.25s ease-in-out;
    }

    &:hover {
      box-shadow: 0 0 7px transparentize($bm-black, 0.4);
      color: white;
      svg {
        color: inherit;
      }
    }
  }

  &--selected {
    box-shadow: 0 0 7px transparentize($bm-black, 0.4);
    color: $bm-orange;
    background-color: white;
    &:hover {
      color: $bm-orange;
    }
    svg {
      color: inherit;
    }
  }
}

.csvExport {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  float: right;

  .chartTypeSelect--button {
    margin: 0;
  }
}
