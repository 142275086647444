@import '../../styles/_variables.scss';

.contactUsPage {
  min-height: 100vh;
  display: flex;
  position: absolute;
  width: 100%;
  flex-direction: column;

  @media all and (min-width: 768px) {
    flex-direction: row;
  }

  .loginBox {
    background-color: rgb(255, 255, 255);
    flex: 50%;
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem 1rem 1rem;
    position: relative;

    @media all and (min-width: 768px) {
      padding: 7rem 4rem 0 4rem;
    }

    .backButton {
      position: absolute;
      left: 0px;
      top: 30px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      color: $gray;
      display: flex;
      align-items: center;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $bm-orange;
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      @media all and (min-width: 768px) {
        left: 40px;
        top: 50px;
      }
    }

    .successPromptContainer {
      display: flex;
      flex-direction: column;
      max-width: 400px;

      .successPromptTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          flex-grow: 0;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background: $active;
          margin-right: 20px;
        }

        span {
          font-weight: 600;
          font-size: 20px;
        }
      }

      .successPromptLabel {
        text-align: center;
      }
    }

    .form {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      .title {
        font-weight: 600;
        color: $bm-orange;
        font-size: 28px;
      }

      .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .subTitle {
        max-width: 400px;
        text-align: center;

        P {
          margin-bottom: 2rem;
        }
      }

      .formInputTextArea {
        min-height: 150px !important;
      }
    }
  }
}
