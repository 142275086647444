.providerForm {
  &__linkedCampaigns {
    margin-top: -25px;
    &__list {
      margin-bottom: 0;
      padding-left: 0;
      list-style-position: inside;
    }
    &__link {
      color: #d10000;
      font-weight: bold;
    }
  }
}
