@import '../../../styles/variables';

.switchLabel {
  color: $bm-black;
}

.webhookFieldRow {
  display: flex;
  border-top: 1px solid white;
  background: #efefef;
}
