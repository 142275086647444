@import '~bootstrap/scss/bootstrap-grid';

.profilePhotoImageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:before {
    position: absolute;
    content: 'Upload profile picture';
    max-width: 60px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.65rem;
    color: gray;
  }
}

.profilePhotoImage {
  max-width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: center;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}

.removeThumb {
  cursor: pointer;
  opacity: 0.3;
  z-index: 1;
  display: inline-flex;
  align-items: flex-start;
  position: relative;
}
