.link {
  display: inline;
  background: none;
  border: 0;
  text-decoration: underline;
  color: currentColor;
}

.muted {
  color: #ababab;
  text-decoration-color: #ababab;
}
