.questionCategory {
  padding: 20px 16px 24px;
  margin-top: 6px;
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 8px;
}

.categoryText {
  color: #bcb5b5;
}

.selectAll {
  font-size: 0.8rem;
  display: inline-block;
  padding-left: 8px;
  text-decoration: underline;
  color: gray;
  border: 0;
  background: none;
  cursor: pointer;

  &:hover {
    color: orange;
  }
}
