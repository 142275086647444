@import '../../../../../styles/variables';
@import '../../../../../styles/bootstrapOverrides';

.reportWidgets-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 30px 30px;

  h1 {
    flex-basis: 100%;
  }
}

.reportWidget {
  flex-basis: calc(33% - 30px);
  border: 1px solid $bm-gray-light;
  border-radius: 6px;
  background-color: lighten($bm-gray-light, 6%);
  box-shadow: 0 0 5px transparentize($bm-black, 0.87);
  padding: 20px;
  margin: 15px;

  @media (max-width: 1400px) {
    flex-basis: calc(49% - 30px);
  }

  @media (max-width: 992px) {
    flex-basis: 100%;
  }
}

.reportCharts {
  border-radius: 5px;
  position: relative;
  padding: 20px 0 0 0;

  .emptyState {
    background: rgba(252, 252, 252, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    color: $bm-gray;
    z-index: 1;
    margin-bottom: 15px;
  }
}
