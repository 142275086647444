@import '../../../../styles/_variables.scss';
@import '../../../../styles/_bootstrapOverrides.scss';

.languagesCard.card {
  margin-top: 10px;
  width: 100%;

  .card-body {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    position: relative;

    .languageText {
      border-radius: 3px;
      color: darken($bm-gray, 30%);
      margin-right: 5px;

      &__default {
        opacity: 0;
        margin-left: 5px;
        font-style: italic;
        font-size: 0.8rem;
        animation: opacityChange 0.4s ease-in-out;
        animation-direction: reverse;
      }
    }

    .removeLanguage,
    .editLanguage {
      color: darken($bm-gray, 30%);
      width: 35px;
      cursor: pointer;
      position: absolute;
      right: 5px;

      &:hover {
        color: $bm-gray;
      }
    }

    .removeLanguage {
      opacity: 1;
    }
  }

  &.activeLanguage {
    .languageText__default {
      opacity: 1;
      animation: opacityChange 0.4s ease-in-out;
    }
  }
}

@keyframes opacityChange {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
