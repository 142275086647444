@import '../../../styles/variables';

.filterContainer {
  background-color: transparentize($color: $bm-gray, $amount: 0.5);
  border-radius: 6px;
}

.borderlessGrid .InovuaReactDataGrid {
  border: none;
}

.borderlessGrid .InovuaReactDataGrid .InovuaReactDataGrid__cell-wrapper {
  border-color: transparent;
}

.columnOptionText,
.columnOptionHeaderText {
  font-size: 13px;
}

.columnOptionHeaderText {
  font-weight: 700;
}

.columnOptionManageBtn {
  background-color: transparent !important;
  border-color: $bm-gray !important;
  color: $bm-black !important;
  font-size: 13px !important;
  border-radius: 0px !important;
  min-width: 200px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  &:hover {
    background-color: transparent;
    border-color: $bm-gray;
    color: $bm-black;
  }
  &:focus {
    background-color: transparent;
    border-color: $bm-gray;
    color: $bm-black;
  }
  &:active {
    background-color: transparent !important;
    border-color: $bm-gray !important;
    color: $bm-black !important;
  }
  &::after {
    content: none !important;
  }
}

.checkBox {
  cursor: pointer;
  height: 16px;
  width: 16px;
}
