.overlaySpinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  &__icon {
    width: 54px;
    height: 54px;
    position: relative;

    .icon {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      &--foreground {
        animation: iconSpin 0.7s infinite ease-in-out;
      }

      &--background {
        animation: iconScale 0.7s infinite ease-in-out;
      }
    }
  }
}

@keyframes iconSpin {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes iconScale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
