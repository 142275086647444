@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '../../styles/_variables.scss';

.actionsNavbar {
  min-height: 80px;
  background-color: transparentize($bm-black, 0.15);
  padding-top: 15px;
  padding-bottom: 15px;

  @media (max-width: 992px) {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 60px;
  }

  .Input {
    display: inline-block;
    width: 200px;
    margin: 0.35rem;
    padding: 0;

    .Label {
      display: none;
    }

    &--w-auto {
      width: auto;
      flex-grow: 1;
    }
  }

  .btnDefault {
    margin: 0.35rem;
    width: auto;
  }

  .btnFilter {
    margin: 0.35rem;
    color: black;
    font-size: 14px;
    letter-spacing: 0px;
    background-color: #f0f0f0;
    text-transform: none;

    &:active,
    &:focus,
    &:hover {
      color: black;
      background-color: #f0f0f0 !important; // important for active state

      &:disabled {
        color: #646464;
      }
    }
  }

  .advanceFilterHeader {
    background-color: #fafafa !important;
    color: $bm-black !important;
  }

  .providers-settings {
    .Input {
      width: 50%;
      max-width: 150px;
    }
  }

  /** Variants **/

  &.light {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    color: #000;
    background-color: #fff;
  }
}

.actionsMenuContainer {
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  top: 64px;
  height: 90px;
  .actionsMenu {
    min-height: 80px;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: 992px) {
      padding-top: 10px;
      padding-bottom: 10px;
      min-height: 60px;
    }

    .Input {
      display: inline-block;
      width: 200px;
      margin: 0.35rem;
      padding: 0;

      .Label {
        display: none;
      }

      &--w-100 {
        width: auto;
        flex-grow: 1;
      }
    }

    .btnDefault {
      margin: 0.35rem;
      width: auto;
    }

    .providers-settings {
      .Input {
        width: 50%;
        max-width: 150px;
      }
    }
  }
}
