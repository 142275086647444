.container {
  border-radius: 10px;
  background: #efefef;
  padding: 20px;
}

.innerContainer {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
}

.campaignTermsEditPage {
  :global {
    .rdw-editor-main {
      min-height: 200px;
    }

    strong {
      font-weight: 900;
    }
  }
}

.customDropDown {
  width: 175px;
}
