@import '~bootstrap/scss/bootstrap-grid';

.postQuestionCategory {
  background: #fafafa;
  padding: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 2rem;
}

.postQuestionCategoryImageContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  margin-left: auto;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    position: absolute;
    content: 'Upload a category image';
    max-width: 60px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.65rem;
    color: gray;
  }

  @include media-breakpoint-up(sm) {
    max-width: 150px;
    max-height: 150px;
  }
}

.postQuestionCategoryImage {
  max-width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: center;
  position: relative;
}

.postQuestionCategoryCounter {
  display: inline-block;
  padding-left: 6px;
}

.formInputInvalid {
  border: 1px solid red !important;
}
