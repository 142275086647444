.analyticsOption {
  padding: 25px;
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px 2px;
}

.analyticsCard {
  padding: 25px 25px 10px 25px;
  border-radius: 15px !important;
  border: none !important;
  background-color: #fafafa !important;
  border-radius: 15px;
}

.analyticsDateCard {
  padding: 8px 20px 8px 20px;
  border-radius: 6px !important;
  border: none !important;
  background-color: #fafafa !important;
  border-radius: 15px;
}

.loadingPlaceholder {
  > .placeholder {
    border-radius: 5px !important;
  }
}

.campaignCard {
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 15px !important;
}

.dateButtonActive {
  border: 2px solid #ff6f00 !important;
  color: #ff6f00 !important;
  font-weight: 500 !important;
}
