@import '../../../../styles/_variables.scss';

.openCampaignStatus {
  padding: 10px;
  margin-right: 15px; // avoid button cuddling on small screens
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 8px;
  letter-spacing: 1px;
  font-weight: bold;

  &--inactive {
    background-color: $inactive;
  }

  &--active {
    background-color: $active;
  }

  &--fallback {
    background-color: $fallback;
  }

  &--draft {
    background-color: $draft;
  }

  &--default {
    background-color: $inactive;
  }
}

.campaignStatus {
  padding: 2px 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: $bm-black-status-pill;
  border-radius: 10px;
  letter-spacing: 1px;
  font-weight: bold;

  &--inactive {
    background-color: $inactive;
  }

  &--active {
    background-color: $active;
  }

  &--fallback {
    background-color: $fallback;
  }

  &--draft {
    background-color: $draft;
  }

  &--default {
    background-color: $inactive;
  }
}
