@import '../../../../styles/_variables.scss';

.imgCoordsGroup {
  display: flex;
  align-items: center;
  margin: 6px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    margin: 0;

    &:nth-child(even) {
      background-color: lighten($bm-gray-light, 2%);
    }
  }

  .coordInput {
    flex-basis: 12%;
    margin-right: 10px;

    @media (max-width: 600px) {
      margin-right: 0;
      flex-basis: 23%;
    }

    .Label {
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1px;

      @media (max-width: 1200px) {
        letter-spacing: 0;
      }

      @media (max-width: 600px) {
        margin-bottom: 0;
      }
    }

    .formInput {
      padding: 5px 10px;

      @media (max-width: 1300px) {
        padding: 3px 6px;
        font-size: 14px;
      }

      @media (max-width: 1200px) {
        padding: 2px 6px;
        height: 40px;
        min-height: auto;
      }

      @media (max-width: 768px) {
        font-size: 12px;
        margin-bottom: 10px;
      }

      &--focused {
        box-shadow: 0 0 9px 0 $bm-orange;
      }
    }

    &--url {
      flex-basis: 48%;
      margin-right: 20px;
      margin-left: 10px;

      @media (max-width: 600px) {
        margin-left: 0;
        margin-right: 0;
        flex-basis: 89%;
      }
    }
  }

  .btnRemove {
    margin-top: 16px;
    margin-right: 10px;
    margin-left: -15px;
    flex-basis: 4%;

    @media (max-width: 600px) {
      margin: 0;
    }
  }
}
