@import '../../../styles/_variables.scss';

.advanceFilterAction {
  background-color: #ffffff !important;
  color: $bm-black !important;
}

.advanceFilterDialog {
  max-width: none !important;
  width: 80vw !important;
}

.switchLabel {
  color: $bm-black;
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: 0;
}
