@use './_variables.scss' as v;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

:root {
  --bs-body-font-family: 'Poppins', var(--bs-font-sans-serif);
}

h1,
h2,
h3,
h3,
h4,
b,
th,
strong {
  font-weight: 500;
}

.text-semibold {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.dropdown-menu .dropdown-item.disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

.text-area {
  padding: 12px;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
