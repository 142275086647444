blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}

/* code, pre>span and span... is for code block */
code {
  color: rgb(33, 37, 41) !important;
  background-color: rgb(241, 241, 241);
  border-radius: 3px;
  font-family: monospace;
  white-space: pre-wrap;
  padding: 1px 10px;
}

pre > span {
  background-color: #f1f1f1 !important;
  overflow-wrap: break-word;
  border-radius: 3px;
  padding: 1px 3px;
}

pre > * > span {
  background-color: rgb(241, 241, 241) !important;
}

pre {
  padding: 1em 10px;
  background: #f1f1f1;
  border-radius: 3px;
}
