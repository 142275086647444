@import '../../styles/_variables.scss';

.authMfaEnrollPage {
  background: $bm-orange;
  min-height: 100vh;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;

  .logo {
    margin: 0 auto 50px auto;
    display: block;
    @media (max-width: 768px) {
      width: 60%;
    }
  }

  .authMfaEnrollForm {
    max-width: 286px;

    .phoneInput {
      background-color: $bm-white;
    }
  }

  .btnDefault {
    background-color: lighten($bm-orange, 15%);
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.13);
    color: darken($bm-orange, 30%);
    width: 100%;
    letter-spacing: 1px;

    &:hover {
      background-color: saturate(lighten($bm-orange, 20%), 10%);
      color: darken($bm-orange, 30%);
      opacity: 0.9;
    }

    &:hover:disabled {
      background-color: lighten($bm-orange, 15%);
      color: darken($bm-orange, 30%);
      opacity: 0.9;
    }
  }

  .formInput {
    width: 100%;
    padding: 0 12px;
    border-radius: 8px;
    border: 1px solid var(--medium-grey, #d1d1d1);
    background: #fff;
    min-height: 48px;;
  }

  .formInput:disabled {
    border: 0;
  }

  .subText {
    color: $bm-white;
    font-size: 0.9em;

    a {
      color: $bm-white;
      text-decoration: underline;
    }
  }
}

.authFormError {
  background-color: $bm-orange-light;
  padding: 5px 10px;
  border-radius: 6px;
  color: $bm-red-dark;
  font-size: 16px;
  max-width: 286px;

  .icon {
    margin-right: 5px;
    opacity: 0.75;
  }
}