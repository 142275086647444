.creator {
  width: 100%;
  margin: 40px auto;
}

.creator-settings {
  width: 100%;

  .Input {
    margin-bottom: 20px;
  }
}

.creator-section {
  user-select: none;
  padding: 3% 7%;
  background-color: white;
  box-sizing: border-box;
  position: relative;
  outline: 1px solid $bm-gray;
  transition: outline 0.2s, box-shadow 0.2s;

  &:hover {
    z-index: 1;
    box-shadow: 0 0 8px 2px transparentize($bm-black, 0.8);
  }

  &--in-edit {
    outline: 1px solid $bm-orange;
    z-index: 1;
    box-shadow: 0 0 8px 2px transparentize($bm-black, 0.8);
  }

  .section-buttons {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(md) {
      right: 5px;
      top: 10px;
    }

    .section-button {
      margin: 0 10px 15px 10px;
      color: $bm-orange;
      font-size: 1.5em;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        font-size: 1.2em;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1em;
      }
    }

    &--draggable-info {
      left: 15px;
      right: auto;

      @include media-breakpoint-down(md) {
        left: 5px;
      }

      .section-button {
        cursor: grab;
      }
    }
  }
}
