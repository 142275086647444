@import '../../../styles/_variables.scss';

.image-map__content {
  border: 1px solid red !important;
  background: radial-gradient(black, transparent);
}

.image-linker-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 21;
  width: 50%;
  max-height: calc(100vh - 100px);
  border-radius: 6px;
  background-color: lighten($bm-gray-light, 6%);
  overflow: hidden;
  padding-top: 70px;

  @media (max-width: 1400px) {
    width: 75%;
  }

  @media (max-width: 992px) {
    max-height: calc(100vh - 30px);
  }

  @media (max-width: 768px) {
    width: calc(100% - 30px);
  }

  &__header {
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    padding: 10px 15px;
    background-color: $bm-orange;
    color: $bm-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
  }

  &__inner-wrapper {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 170px);
  }

  &--backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    z-index: 20;
  }

  &__close-button {
    text-align: right;
    font-size: 20px;
    cursor: pointer;
    transition: opacity 0.25s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  .linker-instructions {
    font-size: 13px;
    width: 100%;
    color: lighten($bm-black, 35%);
  }
}

.image-linker-img-container {
  width: 100%;
  margin: auto;
  padding: 0 15px 15px 15px;
  background-color: $bm-white;
  height: 100%;

  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
}

.image-linker-info-text {
  padding: 5px 10px;
  font-size: 11px;
  color: $bm-black;
  background-color: $bm-white;
  opacity: 0.8;
  max-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  margin: 0;
  cursor: auto;

  &:hover {
    overflow: visible;
    white-space: normal;
    max-height: none;
    width: fit-content;
  }
}

.ReactCrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ReactCrop__crop-selection {
  z-index: 100;
  border-image-source: none;
}

.ReactCrop__image {
  width: 100%;
}
