@import '../../styles/_variables.scss';

.authPage {
  min-height: 100vh;
  display: flex;
  position: absolute;
  width: 100%;
  flex-direction: column;

  :global {
    a {
      text-decoration: none;
      color: $bm-orange;
      font-weight: 600;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 0.7;
        text-decoration: none;
        color: $bm-orange;
      }
    }
  }

  @media all and (min-width: 768px) {
    flex-direction: row;
  }

  .tagLine {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .loginBox {
    background-color: rgb(255, 255, 255);
    flex: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 4rem 1rem 0 1rem;

    @media all and (min-width: 768px) {
      padding: 7rem 4rem 0 4rem;
    }

    :global {
      main {
        flex-grow: 1;
      }
    }
  }

  .return {
    position: absolute;
    left: 40px;
    top: 50px;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  .backIcon {
    color: $gray;
    display: flex;
    align-items: center;
    &:hover {
      color: $bm-orange;
    }
    p {
      margin-bottom: 3px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .imgBox {
    position: relative;
    background: darken($bm-orange, 8);
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .logo {
      position: absolute;
      height: 95%;
      bottom: 0px;
    }
  }

  .glBox {
    width: 350px;
    div[role='button'] {
      border-radius: 10px;
    }
  }

  .orBox {
    display: flex;
    align-items: center;
    p {
      color: lighten($bm-gray, $amount: 20);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    span {
      border: 1px solid lighten($bm-gray, $amount: 20);
      height: 0px;
      display: inline-block;
      width: 110px;
    }
  }
}
