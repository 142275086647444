@import 'src/styles/variables';
.selectedFilters {
  .badge {
    margin: 0 4px 4px 0;
    padding: 2px 10px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    line-height: 1.4;
    background-color: $bm-black-status-pill;
    .badge-value {
      font-weight: bold;
    }
  }
}
