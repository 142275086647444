@import '../../../styles/_variables.scss';
@import '../../../styles/_bootstrapOverrides.scss';

.bannerPickerInput {
  .bannerPickerCard {
    margin-top: 5px;

    .card-body {
      padding: 0 10px 0 0;
      display: flex;
      align-items: center;

      .bannerThumb {
        flex: 0 0 80px;
        width: 80px;
        height: 48px;
        border-radius: 3px;
        background-size: cover;
      }

      .bannerAlt {
        padding: 4px 10px;
        flex-grow: 2;
        font-size: 0.9em;
        color: $bm-gray;
      }

      .removeBanner,
      .previewBanner,
      .infoBanner {
        color: $bm-gray;
        width: 35px;
        cursor: pointer;
        &:hover {
          color: darken($bm-gray, 30%);
        }
      }
    }
  }
}

.formInputError.bannerPickerError {
  margin-top: 5px;
  border-radius: 6px;
  padding: 5px;
}
