.userEdit {
  .btnDefault {
    display: block;
    margin: 0 auto;
  }

  .Input {
    margin-bottom: 20px;
  }

  margin-bottom: 80px;
}
