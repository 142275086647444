@import '../../../styles/variables';

.campaign-url-modal-container {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .label {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 10px;

    &__title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .campaign-url-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__copy-info {
      position: absolute;
      color: $bm-gray;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 10px;
      bottom: -20px;
      left: 22%;
    }

    .formInput {
      background-color: $bm-gray-light;
      box-shadow: none;
      border: none;
      cursor: copy;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .btnDefault {
      box-shadow: none;
      margin-right: 0 !important;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
