@import '../../../styles/_variables.scss';

.outer {
  margin-bottom: 2rem;
}

.statistics {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 75px;
  padding: 0 15px;
  background: #fafafa;

  border: 0;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px 2px;
}

.cell {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.label {
  font-size: 13px;
}

.statistic {
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
}

.statisticMuted {
  margin-left: 10px;
  font-size: 24px;
  font-weight: 700;
  opacity: 0.25;
}

.divider {
  border-left: 1px solid rgb(214, 213, 213);
  height: 25px;
}

.cta {
  margin-left: 20px;
  background: white;
  border: 1px solid #d5d3d3;
  border-radius: 10px;
  padding: 10px 25px;
  white-space: nowrap;
  text-decoration: none;

  .ctaText {
    margin-left: 12px;
    font-weight: 500;
    color: black;
  }

  &:hover {
    .ctaText {
      color: $bm-orange;
    }
  }
}
