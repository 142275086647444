@import '../../../styles/variables';

.tooltipAction {
  height: 16px;
  display: flex;
  font-size: 8px;
  width: 16px;
  background-color: $bm-orange;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
}

.tooltipContainer {
  max-width: 400px !important;
  background-color: #fff !important;
  opacity: 1 !important;
  color: black !important;
  padding: 30px !important;
  min-width: 300px !important;
  z-index: 1056 !important;
  position: absolute !important;
  border-radius: 10px !important;
  top: 18px !important;
  margin-left: 10px !important;
}

.tooltipArrow {
  z-index: 0;
  position: absolute;
  top: 10px !important;
  left: -8px !important;
  background-color: #fff;
  border-right: 8px solid transparent !important;
  border-left: 8px solid rgb(189, 189, 189) !important;
  border-top: 8px solid transparent !important;
  border-bottom: 8px solid rgb(189, 189, 189) !important;
}

.fontReset {
  font-size: initial;
  font-weight: initial;
  text-decoration: initial;
  text-transform: initial;
  line-height: 1.2;
  letter-spacing: 0;
}
