@import '../../../styles/_variables.scss';

.imgBox {
  position: relative;
  background: darken($bm-orange, 8);
  flex: 50%;
  display: flex;
  justify-content: flex-end;
  height: 150px;
  min-height: 150px;

  @media all and (min-width: 768px) {
    height: auto;
  }

  .logo {
    max-width: 95%;
    max-height: 95%;
    object-position: right bottom;
    height: auto;
    vertical-align: bottom;
    object-fit: contain;
    bottom: 0;
    position: absolute;
  }
}
