@import '../../styles/_variables.scss';

// Assigned directly to className={`${styles.newClassName}`}
// .newClassName {}

// Over-ride of old classes
// .newClassName { :global {  .over-write-old-classname { ...styles } } }

.campaignDetailSmallScreenAlert {
  display: block;
  background-color: #f6e3d8;
  text-align: center;
  padding: 0.75rem 0;

  p {
    margin: 0 0 0.15rem;
  }

  small {
    font-size: 0.8rem;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.container {
  padding: 0 25px 0 100px;
  max-width: 1300px;
  margin: 0 auto;
}

.campaignDetail {
  min-width: 768px;
  margin-bottom: 100px; // Stop hover tooltip jumping the page around

  .header {
    &Inner {
      position: relative;
      display: flex;
      align-items: center;
    }

    &Controls {
      display: flex;
      margin-left: auto;
    }
  }

  .providerLink {
    margin-left: 2px;
    font-weight: 500;
    font-size: 14px;
  }

  .backButton {
    position: absolute;
    left: -70px;
    margin-top: 8px;
  }
}

.backIcon {
  color: $gray;
  top: -3px;
  position: relative;

  &:hover {
    color: $bm-orange;
  }
}

.buttonLastChild:last-child {
  margin-right: 0;
}

.campaignActionBar {
  position: sticky;
  top: 80px;
  z-index: 10;
  background-color: #fff;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.iconStyling {
  color: $bm-orange;
  font-size: 1.5em;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

.infoText {
  color: $bm-gray;
  font-size: 14px;
}
