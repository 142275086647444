@import '../../../styles/_variables.scss';

.googleSigninButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--medium-grey, #d1d1d1);
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  img {
    margin-right: 8px;
  }

  .btnText {
    font-size: 14px;
    color: #555;
    font-weight: 500;
  }
}
