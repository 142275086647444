// Bootstrap components overrides
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

button.btn-primary,
a.btn-primary {
  color: $gray-100;
}

// Colours
$primary: $bm-orange;

.alert-danger {
  color: $bm-red-dark;
  background-color: $bm-orange-light;
  border: 0;
}

:root {
  --bs-primary: #ff6f00ff;
  --bs-primary-rgb: 255, 111, 0;
}
