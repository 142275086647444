@import '../../../styles/_variables.scss';

.campaignNotes {
  position: relative;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid $bm-gray-light;
  border-radius: 6px;
  background-color: lighten($bm-gray-light, 6%);
  //box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);

  ol {
    padding-left: 25px;
    margin-bottom: 5px;
    color: lighten($bm-black, 50%);
    font-size: 0.9em;

    @media (max-width: 768px) {
      font-size: 12px;
    }

    li {
      line-height: 1.5;
      margin-bottom: 8px;
    }
  }

  ul {
    padding-left: 26px;

    li {
      margin-bottom: 10px;

      strong {
        color: $bm-orange;
        line-height: 1;
      }
    }
  }

  &_header {
    font-weight: bold;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .campaign-notes-info-icon {
    color: $bm-orange;
    font-size: 24px;
    margin-right: 10px;
    vertical-align: bottom;

    @media (max-width: 768px) {
      font-size: 18px;
      margin-right: 7px;
    }
  }
}
