.rdw-dropdown-selectedtext {
  color: black;
  text-decoration: none;
}

.rdw-editor-main {
  background: white;
}

.passwordInputContainer {
  position: relative;
}

.passwordToggleIcon {
  position: absolute;
  top: 10px;
  right: 15px;
}

.DraftEditor-editorContainer {
  z-index: initial; // Fixes dropdown overlay bug
}
