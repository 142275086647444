@import '../../../styles/variables';

@mixin Button {
  cursor: pointer;
  width: 100%;
  margin: 0.35rem 0;
  padding: 8px 16px;
  text-align: left;
  border: 1px solid #dce0e3;
  border-radius: 8px;
  background: #fff;
  transition: all 0.3s ease;
  user-select: none;
}

.textTitle {
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0.25rem 0 0.5rem;
}

.textBold {
  font-weight: 600;
}

.textDark {
  color: #000;
}

.questionDetail {
  font-size: 0.8rem;
  color: #6c757d;
  padding-right: 5px;
  text-transform: capitalize;

  &:after {
    content: '\2022';
    color: #6c757d;
    font-weight: 600;
    padding-left: 5px;
  }

  &:last-child:after {
    display: none;
  }
}

.btnStd {
  @include Button;
}

.btnChecked {
  @include Button;

  border-color: $bm-green;
  background: lighten($bm-green, 48%);
  box-shadow: lighten($bm-green, 30%) 0 0 0 3px;
}
