@import '../../styles/_variables.scss';

.signUpPage {
  min-height: 100vh;
  display: flex;
  position: absolute;
  width: 100%;
  flex-direction: column;

  @media all and (min-width: 768px) {
    flex-direction: row;
  }

  :global {
    .Input {
      margin-bottom: 10px !important;
    }

    a {
      text-decoration: none;
      color: $bm-orange;
      font-weight: 600;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 0.7;
        text-decoration: none;
        color: $bm-orange;
      }
    }
  }

  .loginBox {
    background-color: rgb(255, 255, 255);
    flex: 50%;
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem 0 1rem;
    position: relative;
    justify-content: space-between;
    justify-content: space-between;

    @media all and (min-width: 768px) {
      padding: 0rem 4rem;
    }

    :global {
      main {
        padding-top: 16px;
        width: 100%;

        @media all and (min-width: 768px) {
          padding-top: 0;
          margin: auto;
        }
      }

      .signUpForm {
        @media all and (min-width: 768px) {
          margin-top: 7rem;
        }
      }
    }

    .backButton {
      position: absolute;
      left: 0px;
      top: 30px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      color: $gray;
      display: flex;
      align-items: center;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $bm-orange;
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

      @media all and (min-width: 768px) {
        left: 40px;
        top: 50px;
      }
    }

    .successPromptContainer {
      display: flex;
      flex-direction: column;
      max-width: 420px;

      .successPromptTitle {
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 24px;
        color: $bm-orange;
        text-align: center;
      }
    }

    .formContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 350px;

      :global {
        form {
          width: 100%;
        }
      }
    }
  }

  .tagLine {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contactUs {
    color: $bm-gray;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    span {
      color: black;
    }
    :hover {
      color: $bm-orange;
    }
  }

  .privacyPolicy {
    color: $bm-gray;
    font-size: 12px;
    max-width: 290px;
    margin-top: 12px;
    margin-bottom: 0;
  }
}
