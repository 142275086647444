.toggleSwitch {
  padding: 8px 12px 8px 45px;
  border: none;
  border-radius: 6px;
  color: $bm-white;
  text-transform: uppercase;
  letter-spacing: 1px;

  &--dark {
    color: $bm-black;
  }

  .toggleSwitch__label {
    cursor: pointer;

    &--lower {
      text-transform: none;
    }
    &--sm {
      font-size: 14px;
      letter-spacing: 0px;
    }
  }

  .toggleSwitch-bg-light {
    background-image: url(../../assets/images/toggle-circle-light.svg) !important;
  }

  .toggleSwitch-bg {
    background-image: url(../../assets/images/toggle-circle.svg) !important;
  }

  .toggleSwitch__input {
    background-color: $bm-gray;
    border-color: $bm-gray;
    height: 24px;
    width: 44px;
    margin-right: 10px;
    margin-bottom: 4px;
    border-width: 4px;

    &:checked {
      background-color: $bm-orange;
      border-color: $bm-orange;
    }

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
    }
  }
}
