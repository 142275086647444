.react-select__control {
  .react-select__indicators {
    margin-right: 12px;
    .react-select__indicator-separator {
      margin: 14px 14px 14px 0;
      border-right: 1px solid hsl(0, 0%, 80%);
      background: none;
    }

    .react-select__dropdown-indicator {
      width: 10px;
      border-color: hsl(0, 0%, 80%);
      opacity: 1;
      height: 10px;
      margin: 0;
      border-right-width: 2px;
      border-bottom-width: 2px;
      display: block;
      border-style: solid;
      border-top-width: 0;
      border-left-width: 0;
      transform: rotate(45deg) translate(-2px, -2px);
      padding: 2px;
      svg {
        display: none;
      }
    }
  }
}
