.inputColor {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
}

.inputColorContainer {
  > .inputColor {
    right: 28px;
    position: relative;
  }
}

.inputTextColor {
  border-radius: 8px;
  height: 35px;
  border: 1px solid #d1d1d1;
  background: #fff;
  padding-left: 10px;
  width: 100%;
}
