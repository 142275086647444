.accordionContainer {
  // Bootstrap modifiers
  :global {
    .accordion-button {
      &::after {
        background-image: url('../../assets/icons/accordion-arrow-down-black.svg') !important;
        height: 13px;
      }

      &:not(.collapsed) {
        color: black !important;
        font-size: 0.8em;
        background-color: #ff6f00 !important;
        border-color: none;
        box-shadow: none;

        &:focus {
          box-shadow: none !important;
        }
      }

      &.collapsed {
        color: var(--bs-body-color) !important;
        border-color: none;
        font-size: 0.8em;
        background-color: #ff6f00 !important;

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }
}
