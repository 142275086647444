@use '../../styles/_variables.scss' as v;

.dashboardContainer {
  max-width: 1800px;
}

.header {
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  color: v.$bm-white;
  min-height: 280px;

  :global {
    p {
      // Ensures the header has a height of 280px minimum
      margin-bottom: 104px;
    }
  }
}

.mainContainer {
  // Ensures the body sits 40px below the header p element
  margin-top: -118px;
  padding: 0 40px;
}

.infoContainer {
  padding: 32px;
  border-radius: 16px;
  background-color: v.$bm-white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  height: 100%;
  display: flex;
  flex-direction: column;

  :global {
    h2 {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.seeAllButton {
  text-decoration: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:hover {
    :global {
      div {
        opacity: 0.7;
      }
    }
  }

  :global {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: v.$bm-orange;
      height: 24px;
      width: 24px;
      transition: opacity 0.2s ease-in-out;
      box-shadow: 2px 2px 2px 0 transparentize(v.$bm-black, 0.87);
    }

    svg {
      margin-left: 1px;
    }
  }
}
