// Colours

$bm-orange: #ff6f00ff;
$bm-orange-light: #ffe1d1;
$bm-black: #000000;
$bm-white: #ffffff;
$bm-gray-light: #ebebeb;
$bm-gray: #a5a5a5;

$bm-red-light: #ea6557;
$bm-red: #e74c3c;
$bm-red-dark: #d10000;
$bm-green: #2ecc71;
$bm-yellow: #ffc107;

$inactive: #f20519;
$fallback: #ff6f00;
$active: #3ccf4e;
$draft: #f9fd2e;
$gray: #e0e0e0;

// Colours adjusted for contrast ratio

$bm-black-status-pill: #151414;
