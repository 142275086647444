.PhoneInput {
  z-index: 0;

  &.phoneInputInvalid {
    margin-bottom: 0px !important;
  }

  &.phoneInputError {
    border: 1px solid red;
    border-radius: 6px;
  }

  .PhoneInputInput {
    border: none;
    position: relative;
    z-index: 0;
    background: transparent;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .PhoneInputCountrySelectArrow {
    width: 10px;
    border-color: hsl(0, 0%, 80%);
    opacity: 1;
    height: 10px;
    margin: 0 6px 6px 0;
    border-right-width: 2px;
    border-bottom-width: 2px;
  }

  .PhoneInputCountry {
    width: fit-content;
    column-gap: 16px;
    border-right: 1px solid hsl(0, 0%, 80%);
    margin: 14px 8px 14px 0;
    padding-right: 8px;
  }

  .phoneInputPlaceholder {
    width: fit-content;
    color: rgb(117, 117, 117);
    top: 0;
    position: absolute;
    white-space: nowrap;
    z-index: -1;
    line-height: 26px;

    &Left-1 {
      left: 26px;
    }

    &Left-2 {
      left: 38px;
    }

    &Left-3 {
      left: 50px;
    }
  }
}
