@mixin cellColorDelete() {
  &:nth-of-type(odd),
  &:nth-of-type(even) {
    td {
      background: rgba(233, 2, 2, 0.05);
      // disable bootstrap hover style
      box-shadow: inset 0 0 0 9999px transparent;
    }
    &:hover {
      td {
        background: rgba(233, 2, 2, 0.05);
        // disable bootstrap hover style
        box-shadow: inset 0 0 0 9999px transparent;
      }
    }
  }
}

.rowHighlightTop {
  border: 4px solid #c30505;
  border-bottom: 0 solid transparent;

  @include cellColorDelete();
}

.rowHighlightBottom {
  border: 4px solid #c30505;
  border-top: 0 solid transparent;

  @include cellColorDelete();
}

.innerCell {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
