@import '../../../../styles/_variables.scss';

.carousel-image-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    justify-content: space-between;
  }

  .carousel-image-tile {
    flex-basis: calc(20% - 20px);
    margin: 10px;

    @media (max-width: 992px) {
      flex-basis: calc(33% - 20px);
    }

    @media (max-width: 768px) {
      flex-basis: calc(50% - 20px);
    }

    @media (max-width: 450px) {
      flex-basis: 100%;
      margin: 10px 0;
    }

    .carousel-image-box {
      width: 100%;
      border: 1px solid $bm-gray-light;
      border-radius: 6px;
      background-color: lighten($bm-gray-light, 2%);
      position: relative;
      overflow: hidden;

      .carousel-image-tile-image {
        object-fit: cover;
        width: calc(100% - 30px);
        margin: 15px 50%;
        transform: translateX(-50%);
        box-sizing: border-box;
      }

      .carousel-image-alt {
        background: transparentize($bm-white, 0.25);
        border-radius: 0 0 10px 10px;
        padding: 10px 25px 10px 10px;
        min-height: 25px;

        .carousel-image-settings {
          position: absolute;
          top: 5px;
          right: 5px;
          color: $bm-orange;
          cursor: pointer;
        }

        .carousel-image-info {
          position: absolute;
          top: 0px;
          right: 0px;
          font-size: 12px;
          color: #ff6f00ff;
          cursor: pointer;
          background-color: $bm-white;
          padding: 4px 30px 4px 8px;
          border-bottom-left-radius: 6px;
          box-shadow: 0 0 6px 1px transparentize($bm-black, 0.8);
        }
      }

      .carousel-image-form {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparentize($bm-white, 0.25);
        border-radius: 0 0 10px 10px;
        padding: 10px;

        .formInput {
          margin-bottom: 5px;
        }
      }
    }
  }
}
