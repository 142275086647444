.fileUploadInput {
  display: inline-flex;
  align-items: flex-start;
  position: relative;

  &.addTopMargin {
    margin-top: 26px;
  }

  @media (max-width: 505px) {
    // Adds undesired spacing on mobile
    // Commented for historical auditing should a visible
    //  issue occur due to this disabling
    // margin-top: 32px;
  }

  .Label {
    position: absolute;
    top: -26px;
  }

  @media (max-width: 505px) {
    margin-top: 32px;
  }

  .removeThumb {
    cursor: pointer;
    opacity: 0.3;
    z-index: 1;
    display: inline-flex;
    align-items: flex-start;
    position: relative;
  }

  .previewThumb {
    padding: 0;
    overflow: hidden;
    position: relative;
    transition: opacity ease-in-out 0.2s;
    display: flex;
    border-radius: 6px;

    .formInput {
      padding: 0;
      border: none;
      box-shadow: 0 0 5px transparentize($bm-black, 0.87);
    }

    &:hover {
      opacity: 0.8;
    }

    img {
      max-height: 48px;
      width: auto;
      cursor: pointer;
      border-radius: 6px;
    }
  }
}
