@import '../../styles/variables';

// Assigned directly to className={`${styles.newClassName}`}
// .newClassName {}

// Over-ride of old classes
// .newClassName { :global {  .over-write-old-classname { ...styles } } }

.offerStatus {
  padding: 10px;
  margin-right: 15px; // avoid button cuddling on small screens
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 8px;
  letter-spacing: 1px;
  font-weight: bold;

  &--active {
    background-color: $active;
  }

  &--inactive {
    background-color: $inactive;
  }
}

.offerDetailSmallScreenAlert {
  display: block;
  background-color: #f6e3d8;
  text-align: center;
  padding: 0.75rem 0;

  p {
    margin: 0 0 0.15rem;
  }

  small {
    font-size: 0.8rem;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.container {
  padding: 0 25px 0 100px;
  max-width: 1300px;
  margin: 0 auto;
}

.offerDetail {
  min-width: 768px;
  margin-bottom: 100px; // Stop hover tooltip jumping the page around

  .header {
    margin-bottom: 2rem;

    &Inner {
      position: relative;
      display: flex;
      align-items: center;
    }

    &Controls {
      display: flex;
      margin-left: auto;
    }
  }

  .providerLink {
    margin-left: 2px;
    font-weight: 500;
    font-size: 14px;
  }

  .backButton {
    position: absolute;
    left: -70px;
    margin-top: 8px;
  }
}

.backIcon {
  color: $gray;
  top: -3px;
  position: relative;

  &:hover {
    color: $bm-orange;
  }
}

.buttonLastChild:last-child {
  margin-right: 0;
}

.offerActionBar {
  position: sticky;
  top: 80px;
  z-index: 10;
  background-color: #fff;
  opacity: 1;
  transition: opacity 1s;
  .header {
    margin-bottom: 0;
  }
}

.iconStyling {
  color: $bm-orange;
  font-size: 1.5em;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}

.imagePreview {
  max-height: 300px;
  max-width: 500px;
  border-radius: 10px;
}

.imagePreviewClose {
  cursor: pointer;
  opacity: 0.3;
  z-index: 1;
}

.tableContainer {
  border-radius: 8px;
  background: #fafafa;
  padding: 15px;
}

.initialAddress {
  display: block;
  font-size: 14px;
  color: #6c757d;
  margin-top: 8px;
  font-style: italic;
  line-height: 1.4;
  padding: 4px 8px;
  background-color: #f8f9fa;
  border-left: 3px solid #ced4da;
  border-radius: 0 4px 4px 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #e9ecef;
    border-left-color: #fd7e14;
  }
}

.previewLink {
  display: flex;
  padding: 1rem 0 2rem 0;
  gap: 0.3rem;
}
