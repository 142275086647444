.statusContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.status {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  overflow: hidden;
  background-color: red;
}

.statusPass {
  background-color: #00bb00;
}

.descriptionContainer {
  white-space: normal;
}
