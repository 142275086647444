// DEAULT INPUT

.Input {
  width: 100%;
  box-sizing: border-box;
  position: relative;

  textarea {
    vertical-align: bottom; // Fixes textarea invisible padding bug
  }

  div[class$='-control'] {
    border: 1px solid #ebebeb;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
    height: auto;
    min-height: 48px;
  }

  div[class$='-placeholder'] {
    font-size: 0.9em;
  }

  &.budget,
  &.maxPerConnection {
    &::after {
      content: '$';
      position: absolute;
      top: 40px;
      transform: translateY(-10%);
      left: 10px;
    }
    .formInput {
      padding-left: 20px;
    }
  }

  &.with-percent-sign {
    &::after {
      content: '%';
      position: absolute;
      top: 40px;
      transform: translateY(-10%);
      left: 8px;
    }
    .formInput {
      padding-left: 20px;
    }
  }

  &--button {
    width: auto;
  }

  &--m-0 {
    button {
      margin-right: 0;
    }
  }

  .invalid {
    border: 1px solid red;
    border-radius: 6px;
  }
}
@media (max-width: 992px) {
  .Input {
    &.budget,
    &.maxPerConnection {
      &::after {
        font-size: 14px;
        transform: translateY(0%);
      }
    }
  }
}

.Label {
  display: block;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 4px;
  color: #252525;
}

.formInput {
  background-image: none;
  border: none;
  padding: 0 12px;
  width: 100%;

  &:not(.btnDefault) {
    min-height: 48px;
  }

  &.formSelect {
    padding: 0;

    [class$='-control'] {
      border: 0;
      box-shadow: none;
    }
  }

  @include media-breakpoint-down(md) {
    height: 42px;
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }
}

.tagSelect {
  border: 0;
  box-shadow: none;
}

// DEFAULT INPUT VARIATIONS

.formInput {
  border-radius: 6px;

  &--bordered {
    border: 1px solid $bm-gray-light;
    box-shadow: 0 0 5px transparentize($bm-black, 0.87);

    &:focus {
      background-color: $bm-gray-light;
    }
  }

  &.invalid {
    border: 1px solid red;
  }

  &--medium {
    min-height: 40px;
    height: 40px;
  }

  &--text-sm {
    font-size: 14px;
  }
}

.formSelect {
  border-radius: 6px;

  &.invalid {
    border: 1px solid red;
  }
}

// DEFAULT INPUT ERROR MESSAGE

.formInputError {
  display: block;
  font-size: 12px;
  color: #d10000;
  font-style: italic;
  line-height: 1.4;
  margin-top: -6px; // Position beneath curved input corners above, avoid too much space on top
  padding: 12px 5px 0 5px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  &.fileUploadError {
    margin: 0 5px;
    border-radius: 6px;
    padding: 10px;
  }
}

// DEFAULT INPUT DESCRIPTION

.formInputDescription {
  font-size: 12px;
  color: $bm-gray;
  padding-left: 5px;
  display: inline-block;

  @at-root .toggleSwitch & {
    padding-left: 0;
    display: block;
    text-transform: none;
    letter-spacing: 0;
  }

  &--short {
    max-width: 240px;
  }
}

// LIST INPUT

.listInput {
  height: 40px;
  min-height: 40px;
  padding: 0 10px;
  font-size: 12px;
}

// FORM CHECKBOX

.form-checkbox {
  margin-left: 43px;
  margin-bottom: 20px;
  text-transform: none !important;
  font-size: 14px;
  letter-spacing: normal !important;
}

.formInput-textarea {
  &--long {
    height: 300px;
  }
}

textarea.formInput {
  padding: 12px;
}
