@import '../../../styles/_variables.scss';
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.resetPasswordBox {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}
.contentBox {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgBox {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  background: darken($bm-orange, 8);
}
.logo {
  position: absolute;
  height: 95%;
  bottom: 0px;
}

.return {
  position: absolute;
  left: 40px;
  top: 50px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.backIcon {
  color: $gray;
  display: flex;
  align-items: center;
  &:hover {
    color: $bm-orange;
  }
  p {
    margin-bottom: 3px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
