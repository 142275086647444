.codeSnippet {
  font-family: 'Consolas', 'Monospaced', 'Courier New', sans-serif;
  background: #eeeeee;
  padding: 10px;
  word-break: break-all;
  cursor: pointer;
}

.codeSnippetCopyText {
  color: #a5a5a5;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  display: block;
  margin-top: -15px;
  margin-bottom: 10px;
}
