@import '../../styles/_variables.scss';

.authForm {
  width: 350px;
  label {
    display: none;
  }

  .otpInput {
    letter-spacing: 1em;
  }

  .formInput {
    border-radius: 8px;
    border: 1px solid var(--medium-grey, #d1d1d1);
    background: #fff;
    margin-bottom: 16px;
  }

  .btnDefault {
    width: 100%;
    letter-spacing: 1px;
    background-color: $bm-orange;
  }

  .formText {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
    .forgotPassword {
      color: $bm-orange;
      transition: opacity 0.2 ease-in-out;
      &:hover {
        opacity: 0.7;
      }
    }
    .contactUs {
      color: $bm-gray;
      span {
        transition: color 0.2s ease-in-out;
      }
      &:hover {
        span {
          color: $bm-orange;
        }
      }
      cursor: pointer;
      span {
        color: black;
      }
    }
  }
  .createAccount {
    color: $bm-orange;
    span {
      color: black;
    }
  }

  .createAccountBlock {
    width: 350px;
    position: absolute;
    bottom: 0;
    .createAccount {
      text-align: center;
      width: 100%;
    }
  }
}

.passcodeInputContainer {
  position: relative;

  .passcodeToggleIcon {
    position: absolute;
    top: 10px;
    right: 15px;
  }
}

.authFormErrorText {
  color: $bm-red;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.authFormAttemptText {
  color: $bm-red;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.authFormError {
  background-color: $bm-orange-light;
  padding: 5px 10px;
  border-radius: 6px;
  color: $bm-red-dark;
  font-size: 16px;
  max-width: 286px;

  &__icon {
    margin-right: 5px;
    opacity: 0.75;
  }
}

.authFormHeader {
  color: $bm-orange;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.authFormResendOtpBtn {
  border: none;
  background: none;
  box-shadow: none;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  line-height: normal;
  color: black;
  padding: 0;
  &:active {
    background-color: none !important;
  }
}

.authFormResendOtpContact {
  color: lighten($bm-gray, 10);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  span {
    color: var(--dark-grey, #252525);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
