.defaultModal {
  .modal-header {
    border: none;
    background-color: $bm-orange;
    color: $bm-white;
    padding: 20px 30px;

    .close {
      color: $bm-white;
      padding: 5px 0;
      opacity: 1;
      font-size: 28px;
    }
  }

  .modal-body {
    padding: 30px 30px;
  }

  .modal-footer {
    border: none;
    background-color: $bm-gray-light;
    padding: 20px 10px;
  }

  &__backdrop.show {
    opacity: 0.75;
  }

  .btnDefault {
    margin-right: 10px;
  }
}
