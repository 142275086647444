.bm-navbar-main {
  height: 80px;

  .nav-link {
    font-size: 13px;
    color: black;

    @media (min-width: 968px) {
      --bs-navbar-nav-link-padding-x: 1rem !important;
    }

    &.active-link {
      color: #fe6f00;
      border-bottom: 2px solid #fe6f00;
    }

    &:hover {
      color: #fe6f00;
    }
  }

  &.sticky-top {
    z-index: 11;
  }

  .navbar-toggler {
    border: 0;
  }

  .dropdown {
    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu {
      background-color: #f8f9fa;
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.13);
      border: 1px solid #dee2e6;
      border-radius: 6px;
      left: auto;
      right: 0;
      top: 65px;
      text-align: right;
      width: 360px;
    }

    .profile-icon {
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }

    .profile-info {
      text-align: left;
      border-top: 1px solid rgb(202, 202, 202);
      border-bottom: 1px solid rgb(202, 202, 202);
      padding: 5px 10px;

      span {
        display: block;
        font-size: 14px;
      }
    }

    .profile-activity-info {
      text-align: left;
      font-size: 14px;
      padding-bottom: 20px;

      p {
        font-weight: bold;
        padding: 15px 10px;
        margin-bottom: 0;
      }

      span {
        display: block;
        padding: 5px 10px;
        border-bottom: 1px solid rgb(202, 202, 202);
      }
    }
  }

  .app-version {
    font-size: 12px;
    padding-left: 15px;
    position: absolute;
    bottom: 25px;
  }

  .navbar-collapse {
    &.collapsing {
      transition: height 0s;
      position: absolute;
      top: 80px;
      right: 0;
    }

    &.show {
      z-index: 2;
      background-color: #f8f9fa;
      text-align: right;
      text-transform: uppercase;
      padding: 15px 30px 15px 40px;
      position: absolute;
      min-width: 30%;
      top: 85px;
      right: 5px;
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.13);
      border: 1px solid #dee2e6;
      border-radius: 6px;

      .nav-link {
        color: black;
      }

      .dropdown-menu {
        padding-top: 0;
        min-width: auto;
        border: none;
        box-shadow: none;

        &.show {
          background-color: #f8f9fa;
          border: none;
          text-align: right;

          .dropdown-item {
            padding-right: 0;
            padding-left: 0;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}
