@import '../../../styles/_variables.scss';

.outer {
  padding-left: 100px;
  padding-right: 25px;
  max-width: 1300px;
  margin: 35px auto 25px;

  .divider {
    border-left: 1px solid rgb(214, 213, 213);
    height: 25px;
  }
  .statusText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .urlContainer {
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    background: #fff;
    min-width: 500px;
  }

  .copy {
    border: none;
    background: transparent;
  }
}
